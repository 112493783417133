import {
  Enum_Artwork_Discipline_Enum,
  Enum_Artwork_Edition_Type_Enum,
  Enum_Collection_Type_Enum,
} from 'common/schema/commonSchemaRemoteTypes';
import { Maybe } from 'graphql/jsutils/Maybe';
import { t } from 'i18next';
import { AudioEntry } from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseRightSide/components/RightTopButtons/components/helper/MultipleAudioPlayerPopover/utils/MultipleAudioPlayerPopoverTypes';
import { canUserEditArtwork } from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseRightSide/components/RightTopButtons/hooks/useShowEditButton';
import { getCDNImageUrl } from 'utils/CDNImages';
import {
  formatNumberToLocale,
  getArtworkStoryAudioLink,
  getCollectionAudioLink,
  getProfileAudioLink,
} from 'utils/utilFunctions';
import {
  Artist,
  ArtistContextType,
  ArtShowcaseType,
  ArtworkByHandleAndSlugType,
  ArtworkCollectionsType,
  SeriesContextType,
  ShowcaseArtworkAvailability,
  ShowcaseArtworkStoryType,
  ShowcasePricesType,
} from './types';

export const hasARExperience = (discipline?: string, vuforiaRating?: Maybe<number>): boolean => {
  if (!vuforiaRating) {
    return false;
  }

  if (
    discipline === Enum_Artwork_Discipline_Enum.Sculpture ||
    discipline === Enum_Artwork_Discipline_Enum.Installation
  ) {
    return false;
  }
  return vuforiaRating >= 2;
};

export const isVuforiaScoreGenerated = (
  vuforiaRating?: Maybe<number>,
  targetId?: Maybe<string>,
  pendingVuforiaPath?: Maybe<string>
): boolean => {
  const hasVuforia = (targetId ?? '').length > 0 && vuforiaRating !== undefined;
  const hasPendingVuforiaPath = (pendingVuforiaPath ?? '').length > 0;
  return (hasVuforia && !hasPendingVuforiaPath) || (!hasVuforia && !hasPendingVuforiaPath);
};

export const getCollectionIds = (
  collections: ArtworkCollectionsType
): {
  ownerId: number | null;
  creatorId: number | null;
  managedById: number | null;
} => {
  let ownerId: number | null = null;
  let creatorId: number | null = null;
  let managedById: number | null = null;

  collections.forEach((collectionData) => {
    const collection = collectionData.Collection;

    if (collection?.type === Enum_Collection_Type_Enum.Owned) {
      ownerId = collection.owner_context_id;
    }

    if (collection?.type === Enum_Collection_Type_Enum.Created) {
      creatorId = collection.owner_context_id;
    }

    if (collection?.type === Enum_Collection_Type_Enum.Managed) {
      managedById = collection.owner_context_id;
    }
  });

  return {
    ownerId,
    creatorId,
    managedById,
  };
};

export const isCurrentLoggedUserCreator = (
  artShowcaseData: ArtworkByHandleAndSlugType,
  currentLoggedUser?: {
    type: string;
    id: number;
  }
): boolean => {
  if (!currentLoggedUser?.type || !currentLoggedUser.id) {
    return false;
  }

  const collections = artShowcaseData.collection_for_owners;
  const mappedCollectionIds = getCollectionIds(collections);
  const canUserEdit = canUserEditArtwork(currentLoggedUser?.type, currentLoggedUser?.id, mappedCollectionIds);

  return canUserEdit;
};

export const getArtworkAvailability = (
  artworkPrices: ShowcasePricesType,
  isTransfered: boolean
): Omit<ShowcaseArtworkAvailability, 'isAvailableForSale'> => {
  const availability: Omit<ShowcaseArtworkAvailability, 'isAvailableForSale'> = {
    isPricePublic: false,
    currency: '',
    price: '',
    isTransfered,
  };

  const lastPriceIndex = artworkPrices?.length - 1;
  availability.currency = artworkPrices?.[lastPriceIndex]?.currency?.toLocaleUpperCase() || '';
  availability.isPricePublic = artworkPrices?.[lastPriceIndex]?.is_public || false;

  const rawPrice = artworkPrices?.[lastPriceIndex]?.price;
  availability.price = rawPrice ? formatNumberToLocale(rawPrice) : '';

  return availability;
};

// Artwork
export const getArtworkStoryVideoLink = (
  artworkStory: ShowcaseArtworkStoryType,
  platform: string
): string | undefined => {
  const videos = artworkStory?.artwork_story_video_links;
  videos?.filter((video) => video.platform === platform);

  return videos?.[0]?.URL || undefined;
};

// Artist
export const getArtistVideoLink = (artistContext: ArtistContextType, platform: string): string | undefined => {
  const videos = artistContext?.profile_video_links;
  videos?.filter((video) => video.platform === platform);

  return videos?.[0]?.URL || undefined;
};

export const getStandaloneEditionId = (
  artwork?: ArtworkByHandleAndSlugType
): ArtShowcaseType['standaloneEditionId'] => {
  if (artwork?.edition_type !== Enum_Artwork_Edition_Type_Enum.StandaloneEdition || !artwork.Editions.length) {
    return undefined;
  }
  return artwork.Editions[0].id;
};

// Series
export const getSeriesVideoLink = (seriesContext: SeriesContextType, platform: string): string | undefined => {
  const videos = seriesContext?.collection_video_links;
  videos?.filter((video) => video.platform === platform);

  return videos?.[0]?.URL || undefined;
};

// Artwork Audio
export const getArtworkStoryAudio = (
  artworkStory: ShowcaseArtworkStoryType,
  artworkTitle?: string
): AudioEntry | null => {
  if (!artworkStory) {
    return null;
  }

  if (!getArtworkStoryAudioLink(artworkStory)) {
    return null;
  }

  const artworkAudio: AudioEntry = {
    title: artworkTitle || t('profileArtShowcaseStrings.rightSide.audioPopup.defaultArtwork'),
    audioLink: getArtworkStoryAudioLink(artworkStory),
  };

  return artworkAudio;
};

// Series Audio
export const getSeriesAudio = (collection: SeriesContextType): AudioEntry | null => {
  if (!collection) {
    return null;
  }

  if (!getCollectionAudioLink(collection)) {
    return null;
  }

  const seriesAudio = {
    title: collection?.name
      ? t('profileArtShowcaseStrings.rightSide.audioPopup.series', { 0: collection?.name })
      : t('profileArtShowcaseStrings.rightSide.audioPopup.defaultSeries'),
    audioLink: getCollectionAudioLink(collection),
  };

  return seriesAudio;
};

// Artist Audio
export const getArtistAudio = (artistContext: ArtistContextType): AudioEntry | null => {
  const artistProfile = artistContext?.profile;
  if (!artistProfile) {
    return null;
  }

  if (!getProfileAudioLink(artistProfile)) {
    return null;
  }
  const artistAudio = {
    title: t('profileArtShowcaseStrings.rightSide.audioPopup.artist'),
    audioLink: getProfileAudioLink(artistProfile),
  };

  return artistAudio;
};

type AudioSearchOptions = {
  artworkTitle?: string;
  artworkStory: ShowcaseArtworkStoryType;
  collection: SeriesContextType;
  artistContext: ArtistContextType;
};

export const getShowcaseAudios = (searchOptions: AudioSearchOptions): AudioEntry[] => {
  const audios: AudioEntry[] = [];

  const artworkAudio = getArtworkStoryAudio(searchOptions.artworkStory, searchOptions.artworkTitle);
  if (artworkAudio) {
    audios.push(artworkAudio);
  }

  const seriesAudio = getSeriesAudio(searchOptions.collection);
  if (seriesAudio) {
    audios.push(seriesAudio);
  }

  const artistAudio = getArtistAudio(searchOptions.artistContext);
  if (artistAudio) {
    audios.push(artistAudio);
  }

  return audios;
};

export const getArtistInformation = (artistContext: ArtistContextType): Omit<Artist, 'contextId'> => {
  const artistProfile = artistContext?.profile;

  const artist: Omit<Artist, 'contextId'> = {
    handle: '',
    image: '',
    title: '',
  };

  artist.handle = artistProfile?.handle || '';
  artist.image = getCDNImageUrl({
    target: 'Profile',
    purpose: 'Avatar',
    targetId: artistContext?.profile?.id ?? 0,
    fileId: artistContext?.profile?.avatar ?? 0,
    meta: { size: 'large' },
  });
  artist.title = artistProfile?.title || '';

  return artist;
};
